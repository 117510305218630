<template>
  <div class="order">
    <a-row class="form">
      <a-col  class="form-item">
        <span class="label"> 订单搜索 </span>
        <a-select default-value="0" style="width: 120px; margin-right: 8px;">
          <a-select-option v-for="(item, key) in orderUUIDOption"
                           :key="key"
                           :label="item.label"
                           :value="item.value + ''">{{item.label}}</a-select-option>
        </a-select>
        <a-input style="width: 360px"></a-input>
      </a-col>
      <a-col class="form-item">
        <span class="label">查询时间  </span>
        <a-range-picker
          class="form-item-select"
          :show-time="{ format: 'HH:mm' }"
          format="YYYY-MM-DD HH:mm"
          :placeholder="['开始时间', '结束时间']"
        />

        <span class="form-item-option active">今日</span>
        <span class="form-item-option">昨天</span>
        <span class="form-item-option">最近7天</span>
        <span class="form-item-option">最近30天</span>
      </a-col>
      <a-col :span="8" class="form-item">
        <span class="label"> 支付方式 </span>
        <a-select
          placeholder="请选取支付方式"
          style="width: 216px">
          <a-select-option v-for="(item, key) in orderPayOption"
                           :key="key"
                           :label="item.label"
                           :value="item.value">{{item.label}}</a-select-option>
        </a-select>
      </a-col>

      <a-col :span="8" class="form-item">
        <span class="label">订单状态</span>
        <a-select
          placeholder="请选取订单状态"
          style="width: 216px">
          <a-select-option v-for="(item, key) in orderStatusOption"
                           :key="key"
                           :label="item.label"
                           :value="item.value">{{item.label}}</a-select-option>
        </a-select>
      </a-col>

      <a-col :span="8" class="form-item">
        <span class="label">订单类型</span>
        <a-select
          placeholder="请选取订单类型"
          style="width: 216px">
          <a-select-option v-for="(item, key) in orderTypeOption"
                           :key="key"
                           :label="item.label"
                           :value="item.value">{{item.label}}</a-select-option>
        </a-select>
      </a-col>

      <a-col class="form-item form-btn">
        <a-button type="primary">筛选</a-button>
        <a-button style="margin: 0 8px;">重置</a-button>
        <span class="download"><my-icon type="iconwenjianxiazai"></my-icon>下载数据</span>
      </a-col>
    </a-row>

    <a-table
      class="order-list"
      :columns="columns"
      :row-key="record => record.uuid"
      :data-source="orders"
      :pagination="pagination"
      :loading="loading"

      :scroll="{ x: '100%'}"
    >
      <div slot="name" slot-scope="device">
        <p> {{ device.deviceUuid }}</p>
        <p> {{ device.deviceName }}</p>
      </div>
    </a-table>

  </div>
</template>

<script>
  export default {
    name: 'order',
    components: {},
    mounted: function () {
    },
    data: function () {
      return {
        orderPayOption: [
          {label: '所有支付', value: 0},
          {label: '无支付', value: 1},
          {label: '支付宝', value: 2},
          {label: '微信支付', value: 3},
        ],
        orderUUIDOption: [
          {label: '订单编号', value: 0},
          {label: '支付编号', value: 1},
        ],
        orderStatusOption: [
          {label: '所有状态', value: 0},
          {label: '开始制作', value: 1},
          {label: '制作成功', value: 2},
          {label: '制作失败', value: 3},
        ],
        orderTypeOption: [
          {label: '所有订单', value: 0},
          {label: '测试订单', value: 1},
          {label: '无支付订单', value: 2},
          {label: '支付订单', value: 3},
          {label: '租赁套餐', value: 4},
          {label: '营销策划', value: 5},
        ],
        orders: [],
        loading: false,
        columns: [
          {
            title: '订单编号/支付编号',
            fixed: 'left',
            scopedSlots: {customRender: 'name'},
            width: 160,
          },
          {
            title: '饮品名称/杯量',
            width: 120,
            dataIndex: "area"
          }, {
            title: '设备名称',
            width: 108,
            dataIndex: "deviceName",

          },
          {
            title: '订单状态',
            width: 108,
            scopedSlots: {customRender: 'onlineStatus'},
          },
          {
            title: '支付金额',
            width: 96,
            scopedSlots: {customRender: 'department'},
          },
          {
            title: '支付方式',
            width: 96,
            scopedSlots: {customRender: 'department'},
          },
          {
            title: '制作类型',
            width: 96,
            scopedSlots: {customRender: 'department'},
          },
          {
            title: '退款状态',
            width: 96,
            scopedSlots: {customRender: 'department'},
          },
          {
            title: '订单时间',
            width: 120,
            scopedSlots: {customRender: 'useLevel'},
          },
          {
            title: '操作',
            width: 80,
            scopedSlots: {customRender: 'more'},
          },
        ],
        pagination: {
          start: 0,
          curPage: 1,
          limit: 10,
          total: 1,
        },
      }
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .order {

    .form {
      width: 960px;
      margin: auto;

      &-btn{
        padding-left: 72px;
      }

      &-item {
        margin-bottom: $middle-space;

        &-select {
          margin-right: $middle-space;
        }

        &-option {
          display: inline-block;
          margin-right: $middle-space;
          cursor: pointer;
        }

        .active{
          color: $theme-color;
        }

        &-option:hover{
          color: $theme-color;
        }

      }
    }

    .label {
      display: inline-block;
      margin: 0 $small-space;
      @include font-little-h();
    }

    .download{
      margin-left: $small-space;
      @include font-little-h();
      color: $theme-color;
      cursor: pointer;
    }

    &-list{
      padding: 0 $container-padding;
    }

    &-list  .ant-table {
      min-height: 200px !important;
    }

    &-list  .ant-empty-normal {
      margin: 100px 0 !important;
    }
  }
</style>
